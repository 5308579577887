import { WEB_ANALYTICS_OOS, ErrorHandlerMixin } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "has-active": false, "scroll": { x: 1500, y: 600 }, "label-item-count": "Results", "has-filter-inactive": false, "data-source": _vm.dataSource, "has-edit-in-row": true }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isEdit != false ? "Edit" : "Can't edit") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "disabled": record.isEdit == false, "icon": "form", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.editComment(record.id);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "ItemCode", attrs: { "data-index": "item", "title": "Item Code", "fixed": "left", "width": 110, "sorter": true } }), _c("text-field", { key: "Facility", attrs: { "data-index": "facility", "title": "Facility", "width": 95, "sorter": true } }), _c("text-field", { key: "StartDate", attrs: { "data-index": "startDate", "title": "Start Date", "parse": _vm.parseDate, "width": 150, "sorter": true } }), _c("text-field", { key: "EndDate", attrs: { "data-index": "endDate", "title": "End Date", "parse": _vm.parseDate, "width": 150, "sorter": true } }), _c("text-field", { key: "ReasonCode", attrs: { "data-index": "reasonID", "title": "Reason Code", "width": 155, "sorter": true, "edit-in-row": "select-input" } }), _c("text-field", { key: "RootCause", attrs: { "data-index": "rootCause", "title": "Root Cause" } }), _c("text-field", { key: "ActionRequired", attrs: { "data-index": "actionRequired", "title": "Action Required", "width": 155 } }), _c("text-field", { key: "ActionWho", attrs: { "data-index": "actionWho", "title": "Action Who", "width": 155 } }), _c("text-field", { key: "EstCuts", attrs: { "data-index": "estimatedCuts", "title": "Estimated Cuts", "width": 155 } })], 1);
};
var staticRenderFns$5 = [];
var ListComments_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$5 = {
  name: "ListComments",
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    country: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editComment(recordId) {
        this.$router.push(`/web-analytics/cut-reason-comment/${recordId}?country=${this.country}`);
      }
    };
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, null, null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ListComments = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-comments-page" }, [_c("resource", { attrs: { "page": _vm.page, "name": "web-analytics.phanned-future-cut-comments", "api-url": _vm.apiUrl } }, [_c("list-comments", { attrs: { "data-source": _vm.dataSource, "country": _vm.country } })], 1)], 1);
};
var staticRenderFns$4 = [];
var index_vue_vue_type_style_index_0_scoped_true_lang$1 = /* @__PURE__ */ (() => ".list-comments-page[data-v-c3e7a6e6] .list__table{background-color:transparent;padding:0!important}\n")();
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: { ListComments },
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    country: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      apiUrl: apiUrl$3,
      page: WEB_ANALYTICS_OOS
    };
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, "c3e7a6e6", null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ListReason = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "has-active": false, "scroll": { x: 1500, y: 600 }, "label-item-count": "Results", "has-filter-inactive": false, "data-source": _vm.dataSource }, scopedSlots: _vm._u([_vm.lastUpdateDate ? { key: "labelItemCount", fn: function(ref) {
    var itemCount = ref.itemCount;
    return [_c("a-space", [_c("span", { staticClass: "h7" }, [_vm._v(_vm._s(itemCount))]), _c("span", { staticClass: "h7" }, [_vm._v(_vm._s("Last Update: " + _vm.lastUpdateDate))])])];
  } } : null], null, true) }, [_c("text-field", { key: "ItemCode", attrs: { "data-index": "item", "title": "Item Code", "fixed": "left", "width": 110, "sorter": true } }), _c("text-field", { key: "Facility", attrs: { "data-index": "facility", "title": "Facility", "width": 95, "sorter": true } }), _c("text-field", { key: "StartDate", attrs: { "data-index": "startDate", "title": "Start Date", "parse": _vm.parseDate, "width": 150, "sorter": true } }), _c("text-field", { key: "EndDate", attrs: { "data-index": "endDate", "title": "End Date", "parse": _vm.parseDate, "width": 150, "sorter": true } }), _c("text-field", { key: "ReasonCode", attrs: { "data-index": "reasonID", "title": "Reason Code", "width": 155, "sorter": true, "edit-in-row": "select-input" } }), _c("text-field", { key: "RootCause", attrs: { "data-index": "rootCause", "title": "Root Cause" } }), _c("text-field", { key: "ActionRequired", attrs: { "data-index": "actionRequired", "title": "Action Required", "width": 155 } }), _c("text-field", { key: "ActionWho", attrs: { "data-index": "actionWho", "title": "Action Who", "width": 155 } }), _c("text-field", { key: "EstCuts", attrs: { "data-index": "estimatedCuts", "title": "Estimated Cuts", "width": 155 } })], 1);
};
var staticRenderFns$3 = [];
var ListShapePointComment_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$3 = {
  name: "ListShapePointComment",
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    country: {
      type: String,
      default: ""
    },
    lastUpdateDate: {
      type: String,
      default: ""
    }
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ListShapePointComment$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-shape-point-comments-page" }, [_c("resource", { attrs: { "page": _vm.page, "name": "web-analytics.phanned-future-cut-comments.sharepoint", "api-url": _vm.apiUrl } }, [_c("list-shape-point-comment", { attrs: { "data-source": _vm.dataSource, "country": _vm.country, "lastUpdateDate": _vm.lastUpdateDate } })], 1)], 1);
};
var staticRenderFns$2 = [];
var index_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".list-shape-point-comments-page[data-v-299cb73a] .list__table{background-color:transparent;padding:0!important}\n")();
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: { ListShapePointComment: ListShapePointComment$1 },
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    country: {
      type: String,
      default: ""
    },
    lastUpdateDate: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      apiUrl: apiUrl$2,
      page: WEB_ANALYTICS_OOS
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "299cb73a", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ListShapePointComment = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-comments" }, [_c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header-container mb-3 px-4 pb-2", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("span", { staticClass: "title" }, [_vm._v("Cuts Reason/Comment Maintenance")])])], 1)], 1), _c("div", { staticClass: "body mx-5" }, [_c("div", { staticClass: "card p-3" }, [_c("a-spin", { attrs: { "spinning": _vm.isFetchingData } }, [_c("div", { staticClass: "header-title" }, [_vm._v("Get Data From")]), _c("a-row", { attrs: { "gutter": 16, "type": "flex", "justify": "space-between", "align": "middle" } }, [_c("a-col", { staticClass: "pb-2", attrs: { "span": 4 } }, [_c("select-input", { key: "Country", attrs: { "label": "Country", "data-source": _vm.countries, "source": "country", "source-label": _vm.parseSourceLabel("country", ["country", "countryName"]), "default-active-first-option": "", "reset-defaut-first-option": false }, on: { "change": function($event) {
    return _vm.updateQuery("country", $event);
  } } })], 1), _c("a-col", { staticClass: "pb-2", attrs: { "span": 5 } }, [_c("select-input", { key: "Facility", attrs: { "label": "Facility", "data-source": _vm.facilities, "value": _vm.selectedFacility, "source": "facility", "source-label": _vm.parseSourceLabel("facility", ["facility", "facilityName"]), "mode": "multiple", "max-tag-count": 1 }, on: { "change": _vm.onFacilityChange } })], 1), _c("a-col", { staticClass: "pb-2", attrs: { "span": 5 } }, [_c("text-input", { key: "Item", attrs: { "label": "Item", "placeholder": "Type Item Code" }, on: { "change": _vm.onItemCodeChange } })], 1), _c("a-col", { staticClass: "pb-2", attrs: { "span": 5 } }, [_c("date-picker", { attrs: { "label": "Start Date", "value": _vm.dateObj.startDate }, on: { "change": function($event) {
    return _vm.onDateChange("startDate", $event);
  } } })], 1), _c("a-col", { staticClass: "pb-2", attrs: { "span": 5 } }, [_c("date-picker", { attrs: { "label": "End Date", "value": _vm.dateObj.endDate }, on: { "change": function($event) {
    return _vm.onDateChange("endDate", $event);
  } } })], 1)], 1), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("div", { staticClass: "w-100 d-flex justify-content-end align-items-center pt-3" }, [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onGetDataClick } }, [_vm._v(" Get Data ")])], 1)])], 1)], 1)], 1), _c("div", { staticClass: "mt-3 card" }, [_c("div", { staticClass: "p-3" }, [_c("list-reason", { attrs: { "data-source": _vm.listComments, "country": _vm.country } })], 1)]), _c("div", { staticClass: "mt-3 card" }, [_c("div", { staticClass: "p-3" }, [_c("list-shape-point-comment", { attrs: { "data-source": _vm.listShapePointComment, "country": _vm.country, "lastUpdateDate": _vm.lastUpdateDate } })], 1)])])]);
};
var staticRenderFns$1 = [];
var FilterComment_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".list-comments[data-v-2710c18d]{padding-bottom:96px}.list-comments .header-container[data-v-2710c18d]{background-color:#fff}.list-comments .header-title[data-v-2710c18d]{font-style:normal;font-weight:500;font-size:16px;color:#272d35;margin-bottom:16px}.list-comments .title[data-v-2710c18d]{font-style:normal;font-weight:500;font-size:20px;line-height:28px}.list-comments .card[data-v-2710c18d]{background:#ffffff;border:1px solid #eaedf0}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const COUNTRY_NAME_LIST = {
  AU: "Australia",
  NZ: "New Zealand"
};
const __vue2_script$1 = {
  name: "FilterComment",
  components: { ListReason, ListShapePointComment },
  inject: ["resourceProps"],
  mixins: [ErrorHandlerMixin],
  data() {
    const [
      facilityProps,
      itemCodeProps,
      countriesProps,
      commentProps,
      shapePointCommentProps
    ] = this.resourceProps;
    return {
      apiUrl: apiUrl$1,
      commentProps,
      facilityProps,
      itemCodeProps,
      countriesProps,
      shapePointCommentProps,
      defaultDatesUrl: `${apiUrl$1}/web-analytics/common/default-dates`,
      dateObj: {},
      country: "AU",
      selectedFacility: ["All"],
      lastUpdateDateUrl: `${apiUrl$1}/web-analytics/common/sharepoint-lastest-date`,
      lastUpdateDate: ""
    };
  },
  computed: {
    facilities() {
      let listFacilities = this.facilityProps.crud.getList();
      listFacilities.splice(0, 1);
      return [
        {
          facility: "All",
          facilityName: ""
        },
        ...listFacilities
      ];
    },
    listComments() {
      return this.commentProps.crud.getList();
    },
    listShapePointComment() {
      return this.shapePointCommentProps.crud.getList();
    },
    countries() {
      return this.countriesProps.crud.getList().slice(0, 2).map((country) => ({
        ...country,
        countryName: COUNTRY_NAME_LIST[country.country]
      }));
    },
    isFetchingData() {
      return !this.countries.length || !this.facilities.length;
    }
  },
  watch: {
    country(newVal) {
      if (newVal) {
        this.fetchFacilityList();
      }
    }
  },
  created() {
    this.fetchCountryList();
    this.getDefaultDate();
    this.fetchFacilityList();
    this.getLastUpdateDate();
  },
  beforeDestroy() {
    this.commentProps.crud.deleteQueryStrings();
    this.shapePointCommentProps.crud.deleteQueryStrings();
  },
  methods: {
    convertQueryString(params) {
      let query = "";
      for (const [key, value] of Object.entries(params)) {
        query += value ? `${key}=${value}` : "";
      }
      return query;
    },
    fetchFacilityList() {
      this.fetchList(this.facilityProps, { country: this.country });
    },
    fetchCommentList() {
      const pageSize = this.commentProps.crud.getPagination().pageSize;
      this.commentProps.crud.setPagination({ page: 1, pageSize });
      this.fetchList(this.commentProps);
    },
    fetchShapePointCommentList() {
      const pageSize = this.shapePointCommentProps.crud.getPagination().pageSize;
      this.shapePointCommentProps.crud.setPagination({ page: 1, pageSize });
      this.fetchList(this.shapePointCommentProps);
    },
    fetchItemCodeList() {
      this.fetchList(this.itemCodeProps, { country: this.country });
    },
    fetchCountryList() {
      this.fetchList(this.countriesProps);
    },
    async getDefaultDate() {
      const startDate = this.$moment();
      const endDate = this.$moment().add(1, "M");
      this.dateObj = { startDate, endDate };
      this.updateQuery("startDate", this.$moment(startDate).format("YYYY-MM-DD"));
      this.updateQuery("endDate", this.$moment(endDate).format("YYYY-MM-DD"));
    },
    fetchList(resourceProps, params = {}) {
      const isCustomQuery = ![
        "web-analytics.phanned-future-cut-comments",
        "web-analytics.phanned-future-cut-comments.sharepoint"
      ].includes(resourceProps.resourceName);
      const query = Object.keys(params).length > 0 ? this.convertQueryString(params) : null;
      resourceProps.crud.deleteFilter("IsInactive");
      resourceProps.crud.fetchList(null, true, true, query, isCustomQuery);
    },
    generateLabel(option, arrKeyDisplay) {
      let str = "";
      arrKeyDisplay.map((keyItem, keyIndex) => {
        if (keyIndex !== 0)
          str += option[keyItem] ? ` - ${option[keyItem]}` : "";
        else
          str += option[keyItem];
      });
      return str;
    },
    getAllOption(arrayOption, key) {
      return arrayOption.slice(1).map((arrayOption2) => arrayOption2[key]).join();
    },
    onItemCodeChange(val) {
      this.updateQuery("item", val);
    },
    parseSourceLabel(keyCondition, keyDisplay = null) {
      if (Array.isArray(keyDisplay)) {
        return (option) => option[keyCondition] !== "" ? this.generateLabel(option, keyDisplay) : "All";
      } else
        return (option) => option[keyCondition] !== "" ? option[keyDisplay || keyCondition] : "All";
    },
    onDateChange(key, date) {
      this.updateQuery(key, date);
      this.dateObj[key] = date;
    },
    async onGetDataClick() {
      await Promise.all([
        this.fetchCommentList(),
        this.fetchShapePointCommentList()
      ]);
    },
    onFacilityChange(sourceFacility) {
      const lastSelectedItem = sourceFacility[sourceFacility.length - 1];
      if (Array.isArray(sourceFacility)) {
        if (lastSelectedItem === "All" || !sourceFacility.length) {
          this.selectedFacility = ["All"];
          this.updateQuery("facility");
        } else {
          this.selectedFacility = sourceFacility.filter((item) => item !== "All");
          this.updateQuery("facility", this.selectedFacility.join());
        }
      }
    },
    updateQuery(keyFilter, value = "") {
      if (keyFilter === "country") {
        this.country = value;
      }
      this.commentProps.crud.setQueryString(keyFilter, value);
      this.shapePointCommentProps.crud.setQueryString(keyFilter, value);
    },
    submit() {
      this.$router.push(`/web-analytics/cut-reason-comment/create`);
    },
    async getLastUpdateDate() {
      try {
        const res = await this.axios.get(this.lastUpdateDateUrl);
        this.lastUpdateDate = this.$moment(res.data.lastestDate).format("DD/MM/YYYY");
      } catch (error) {
        this.displayErrorNotification(error);
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "2710c18d", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterComment = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": [
    "web-analytics.common.facilities",
    "web-analytics.common.oo-cuts-item-codes",
    "web-analytics.common.countries",
    "web-analytics.phanned-future-cut-comments",
    "web-analytics.phanned-future-cut-comments.sharepoint"
  ], "api-url": _vm.apiUrl } }, [_c("filter-comment")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { FilterComment },
  data() {
    return {
      page: WEB_ANALYTICS_OOS,
      apiUrl,
      itemsMenu: [
        {
          key: "casefillreporting",
          title: "Service Level Maintenance",
          path: ""
        },
        {
          key: "cutreasoncomment",
          title: "Cut Reason/Comment Maintenance",
          path: "/web-analytics/cut-reason-comment"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
